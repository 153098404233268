import PropTypes from 'prop-types'
import React from 'react'
import Swiper from 'react-id-swiper'
import Button from 'components/common/button'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'
import Loader from 'components/loader'
import { SEE_ALL, TRAILING } from 'components/recently-view/const'
import { BUTTON_TYPES } from 'constants/button'
import QuickAddToBagModal from 'components/shared/quick-add-to-bag-modal/QuickAddToBagModal'
import ProductItem from '../product-item'
import SeeAllCard from '../product-item/see-all-card'
import styles from './style.scss'

class ProductListSlider extends React.PureComponent {
  renderProductItem = (product, idx) => {
    const {
      displayBadges,
      displayPrice,
      displayDescription,
      displaySwatches,
      displayWishlistIcon,
      displayQuickAddToCart,
      refId,
      refType,
      seeAllCardHeight,
      seeAllCardWidth,
      setFirstProductRef,
      subPosition,
    } = this.props

    if (product.id_product === TRAILING) {
      return <div className="trailing-space" />
    }

    if (product.id_product === SEE_ALL) {
      return (
        <SeeAllCard
          key={product.id_product}
          refType={refType}
          seeAllCardHeight={seeAllCardHeight}
          seeAllCardWidth={seeAllCardWidth}
        />
      )
    }

    return (
      <div className="product-item-slider-wrapper">
        <ProductItem
          key={product.product_id || product.id_product || product.id_product_2}
          className="product-item-slide"
          displayBadges={displayBadges}
          displayDescription={displayDescription}
          displayPrice={displayPrice}
          displaySwatches={displaySwatches}
          displayWishlistIcon={displayWishlistIcon}
          displayManufacturerName
          idx={idx + 1}
          refId={refId}
          refType={refType}
          setFirstProductRef={idx === 0 && setFirstProductRef}
          subPosition={subPosition}
          product={product}
          promptLocation="wishlist_appfeed"
          {...product}
        />
        {displayQuickAddToCart && (
          <QuickAddToBagModal
            id_product={product.id_product}
            product_name={product?.name}
            sizes={product?.sizes}
            id_site_category={product?.id_category_default}
          />
        )}
      </div>
    )
  }

  renderArrow(arrowClass, buttonClass, customArrowStyle, src) {
    return (
      <Button
        className={buttonClass}
        style={customArrowStyle}
        type={BUTTON_TYPES.containedLight}
      >
        <Icon className={arrowClass} src={src} size={ICON_SIZE.small} />
      </Button>
    )
  }

  renderProductSlider() {
    const {
      customArrowProps,
      customSliderOptions,
      displayBullets,
      isPhone,
      list,
    } = this.props
    const { class: customArrowClass, style: customArrowStyle } =
      customArrowProps
    const sliderOptions = {
      spaceBetween: 10,
      slidesPerView: 2.5,
      roundLengths: true,
      shouldSwiperUpdate: true,
      ...(displayBullets && {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      }),
      ...(!isPhone && {
        navigation: {
          nextEl: `.${customArrowClass.rightArrow}`,
          prevEl: `.${customArrowClass.leftArrow}`,
        },
        renderPrevButton: () =>
          this.renderArrow(
            'left-arrow',
            customArrowClass.leftArrow,
            customArrowStyle,
            ICONS.arrowHeadLeft,
          ),
        renderNextButton: () =>
          this.renderArrow(
            'right-arrow',
            customArrowClass.rightArrow,
            customArrowStyle,
            ICONS.arrowHeadRight,
          ),
      }),
      ...customSliderOptions,
    }

    return (
      <Swiper {...sliderOptions}>{list.map(this.renderProductItem)}</Swiper>
    )
  }

  renderLoading() {
    return (
      <div>
        <style jsx>{styles}</style>
        <div className="slider-loader">
          <Loader />
        </div>
      </div>
    )
  }

  render() {
    const { list } = this.props

    if (!list?.length) {
      return this.renderLoading()
    }

    return this.renderProductSlider()
  }
}

ProductListSlider.defaultProps = {
  customArrowProps: {},
  customSliderOptions: {},
  displayBadges: true,
  displayBullets: true,
  displayDescription: false,
  displayPrice: true,
  displaySwatches: false,
  displayWishlistIcon: false,
  displayQuickAddToCart: false,
  isPhone: undefined,
  list: [],
  refId: '',
  refType: '',
  subPosition: '',
}

ProductListSlider.propTypes = {
  customArrowProps: PropTypes.shape({
    class: PropTypes.shape({
      leftArrow: PropTypes.string,
      rightArrow: PropTypes.string,
    }),
    style: PropTypes.shape({}),
  }),
  customSliderOptions: PropTypes.shape({}),
  displayBadges: PropTypes.bool,
  displayBullets: PropTypes.bool,
  displayDescription: PropTypes.bool,
  displayPrice: PropTypes.bool,
  displaySwatches: PropTypes.bool,
  displayWishlistIcon: PropTypes.bool,
  displayQuickAddToCart: PropTypes.bool,
  firstProductRef: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  refId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  refType: PropTypes.string,
  seeAllCardHeight: PropTypes.number.isRequired,
  seeAllCardWidth: PropTypes.number.isRequired,
  setFirstProductRef: PropTypes.func.isRequired,
  subPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ProductListSlider
