import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ProductListSlider from 'components/product/product-list-slider'
import usePersonalizedRecommendation from 'components/shared/useEffect/usePersonalizedRecommendation'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { customFlagValue, isFlagEligible } from 'lib/utils/handle-flag/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import CartRecommendationSkeleton from '../cart-recommendation/skeleton'
import style from './style.scss'

const CartJustForYou = ({ auth, isPhone, t }) => {
  const [personalizedProduct, isFetched] = usePersonalizedRecommendation(
    auth,
    null,
    true,
  )

  const headerTitle = hasLocizeTranslation(
    t,
    'CART_JUST_FOR_YOU_TITLE',
    'Just For You',
  )
  const headerSubTitle = hasLocizeTranslation(
    t,
    'CART_JUST_FOR_YOU_SUB_TITLE',
    'Discover handpicked styles & trends',
  )

  const shoppingBagRedesignFlagValue = customFlagValue({
    flagName: 'shoppingBagRedesign',
  }) // TODO: force value to handle with flagsmith later (ShoppingBagRedesign-20230101 old flag name)
  const isShoppingBagNewDesign = isFlagEligible(shoppingBagRedesignFlagValue)
  const productList = personalizedProduct?.products?.slice(0, 8)
  const isLoyaltyTwoPointO = useFlagsmithGetFlagValue(
    'Loyalty2.0_20240808',
    false,
  )

  if (!isFetched || !productList) {
    return (
      <CartRecommendationSkeleton
        justForYou
        isShoppingBagNewDesign={isShoppingBagNewDesign}
      />
    )
  }

  return (
    <Fragment>
      <style jsx>{style}</style>
      <div
        className={clsx('cart-just-for-you', {
          'new-design': isShoppingBagNewDesign,
        })}
      >
        <div className="cart-just-for-you__header">
          <div className={clsx('header-title', Typo.subtitle1)}>
            {headerTitle}
          </div>
          <div className={clsx('header-sub-title', Typo.body2)}>
            {headerSubTitle}
          </div>
        </div>
        <div className="cart-just-for-you__product-container">
          <ProductListSlider
            customArrowProps={{
              class: {
                leftArrow: 'cart-just-for-you__prev-btn',
                rightArrow: 'cart-just-for-you__next-btn',
              },
            }}
            displayPremiumTags
            displayDescription
            displayWishlistIcon
            displayQuickAddToCart={isLoyaltyTwoPointO}
            displayBullets={false}
            isPhone={isPhone}
            list={productList}
            refId=""
            refType="cart_just_for_you"
          />
        </div>
      </div>
    </Fragment>
  )
}

CartJustForYou.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    auth: state.auth,
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(CartJustForYou)
